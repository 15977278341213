
<footer id="footer" class="d-flex  align-items-center justify-content-between mat-elevation-z2">

  <div class="d-flex align-items-center">

    <a class="link" (click)="termsModal()">Terms <span class="d-sm-inline d-none">&amp; conditions</span></a>

    <span class="mx-sm-2 mx-1">|</span>

    <a class="link" (click)="privacyModal()">Privacy policy</a>

  </div>

  <div>
    <p class="my-0">&copy; Aista 2021 - {{currentYear}}<span class="d-sm-inline d-none">, all rights reserved</span></p>
  </div>

</footer>