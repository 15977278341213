
<h2 mat-dialog-title>Privacy Policy</h2>

<mat-dialog-content>
  <p>This policy applies to all our all existing and new users as of 15th of August 2022.</p>

  <h3>Scope</h3>
  <p>
    Aista Ltd (Aista, “we”) might collect and process personal data of users of UpCloud's website and online
    services. This Privacy Policy applies to personal data we obtain from our customers and their representatives
    (“Users”) through our websites when the relevant User registers to UpCloud's cloud-based infrastructure and software
    services (collectively, the “Services”). In addition, some of our services might be subject to a separate privacy
    policy. If a separate privacy policy applies to a particular service, we will post it on the relevant website or
    otherwise in connection with the service in question.
  </p>
  <p>
    Our Privacy Policy explains what data we process, how we do that and how the data subjects, i.e. Users, may use
    their rights (e.g. right to object, right of access).
  </p>
  <p>
    This Privacy Policy may be updated if required in order to reflect the changes in data processing practices or
    otherwise. The current version can be found on our website aista.com. We will not make substantial changes to
    this Privacy Policy or reduce the rights of Users under this Privacy Policy without providing a notice thereof.
  </p>
  <p>
    This Privacy Policy only covers data processing carried out by Aista. The Privacy Policy does not address, and we
    are not responsible for, the privacy practices of any third parties. Aista disclaims all responsibility for the
    processing carried out by third parties, also in cases where Services include hyperlinks to third parties' websites.
  </p>
  <p>
    Please note that this Privacy Policy applies to processing of personal data carried out by Aista as a data
    controller. As regards the data Aista's customers or Users insert into Services while using the Services, Aista
    registers this personal data as a data processor and the relevant customer shall be considered to be the data
    controller with regard to this personal data.
  </p>
  <p>
    Privacy, security and online safety are important for us, and we process all personal data with due care and in
    accordance with applicable laws and regulations.
  </p>

  <h3>Personal data processed and sources of data.</h3>

  <p>
    We collect two types of information from the Users: (i) User Data; and (ii) Technical Data. Although we do not
    normally use Technical Data to identify individuals, sometimes individuals can be recognized from it, either alone
    or when combined or linked with User Data. In such situations, Technical Data can also be considered to be personal
    data under applicable laws and we will treat the combined data as personal data.
  </p>
  <p>
    Aista may collect and process for example the following User Data from Users: (i) name and contact details; (ii)
    e-mail address; (iii) phone number; (iv) invoicing and billing information; (v) other personal data Users provide
    themselves.
  </p>
  <p>
    The specific kind of User Data collected will depend on the Services used by the User. Most of the User Data is
    received directly from Users at the point of registration or otherwise at beginning of and during the customer
    relationship.
  </p>
  <p>
    Technical Data we gather in connection with the use of our Services includes for example the following data: (i)
    User's IP address; (ii) browser type and version; (iii) preferred language; (iv) geographic location using IP
    address or the GPS, wireless, or Bluetooth technology on your device; (v) operating system and computer platform;
    (vi) the full Uniform Resource Locator (URL) clickstream to, through, and from our Services, including date and
    time; (vii) products or services User viewed or searched for while using our Services; and (viii) areas of our
    Services User has visited.
  </p>

  <h3>Cookies.</h3>

  <p>
    We use various technologies to collect and store Technical Data and other information when Users visit our Services,
    including cookies. Cookies allow us to calculate the aggregate number of people visiting our Services and monitor
    the use of the Services. This helps us to improve our Services and better serve our Users. We may also use cookies
    that make the use of the Services easier, for example by remembering usernames, passwords and preferences. We may
    use tracking and analytics cookies to see how well our Services are being received by our Users.
  </p>
  <p>
    Cookies are created and stored on the basis of the consent given by the user. The users give their consent through
    the pop-up window in upcloud.com and its subdomains.
  </p>

  <h3>Web analytics services.</h3>

  <p>
    Our Services use several web analytics services to compile Technical Data and reports on visitor usage and to help
    us improve our Services.
  </p>

  <h3>Purposes.</h3>

  <p>
    There are several purposes for the processing of personal data by Aista. Personal data is processed by Aista for
    managing the relationships with customers, facilitating transactions and payments, and for managing, developing and
    analysing our customer service. Personal data is also used to direct marketing at special customer groups, and other
    business activities related to any of these activities.
  </p>

  <p>Personal data is also processed by UpCloud for the following purposes:</p>

  <h3>To provide our Services and carry out our contractual obligations.</h3>

  <p>
    We process personal data in the first place to be able to offer the Services to our customers and Users and to run,
    maintain and develop our business. In some cases, personal data may be processed in order to carry out our
    contractual obligations towards the User. We may use the data for example to offer essential functionalities of the
    Services and to provide access to the Services. If User contacts our customer service, we will use the provided
    information for answering questions and solving possible issues.
  </p>

  <h3>For customer communication.</h3>

  <p>
    We may process personal data for the purpose of contacting Users regarding our Services and to inform Users of
    changes in our Services. Data may also be used for research and analysis purposes in order to improve our Services.
  </p>

  <h3>For quality improvement and trend analysis.</h3>

  <p>
    We may process information regarding the use of the Services to improve the quality of our Services e.g. by
    analysing any trends in the use of our Services. When possible, we will do this using only aggregated, non-
    personally identifiable data.
  </p>

  <h3>Legitimate grounds for processing.</h3>

  <p>
    We process personal data to perform our contractual obligations towards Users and to comply with legal obligations.
    Furthermore, we process personal data to pursue our legitimate interest to run, maintain and develop our business.
  </p>

  <p>
    In some parts of the Services, Users may be requested to grant their consent for the processing of personal data. In
    this event, Users may withdraw their consent at any time.
  </p>

  <h3>Transfer to countries outside of Europe.</h3>

  <p>
    We store the Users' personal data primarily within the European Economic Area. However, we have service providers in
    several geographical locations. As such, we and our service providers may transfer personal data to, or access it
    in, jurisdictions outside the European Economic Area or the User's domicile.
  </p>

  <p>
    We will take steps to ensure that the Users' personal data receives an adequate level of protection in the
    jurisdictions in which it is processed. We provide adequate protection for the transfers of personal data to
    countries outside of the European Economic Area through a series of agreements with our service providers based on
    the Standard Contractual Clauses or other similar arrangements.
  </p>

  <p>
    More information regarding the transfers of personal data may be obtained by contacting us on addresses mentioned in
    this Privacy Policy.
  </p>

  <h3>Recipients.</h3>

  <p>
    We only share personal data within the organisation of Aista if and as far as reasonably necessary to perform and
    develop our Services. We do not share personal data with third parties outside of Aista's organization unless one
    of the following circumstances applies:
  </p>

  <h3>It is necessary for the purposes set out in this Privacy Policy</h3>

  <p>
    To the extent that third parties need access to personal data to perform the Services, Aista has taken appropriate
    contractual and organisational measures to ensure that personal data are processed exclusively for the purposes
    specified in this Privacy Policy and in accordance with all applicable laws and regulations.
  </p>

  <h3>For legal reasons</h3>

  <p>
    We may share personal data with third parties outside Aista's organization if we have a good-faith belief that
    access to and use of the personal data is reasonably necessary to: (i) meet any applicable law, regulation, and/or
    court order; (ii) detect, prevent, or otherwise address fraud, security or technical issues; and/or (iii) protect
    the interests, properties or safety of Aista, our Users or the public in accordance with the law. When possible,
    we will inform Users about such transfer and processing.
  </p>

  <h3>To authorized service providers.</h3>

  <p>
    We may share personal data to authorized service providers who perform services for us (including data storage,
    sales, marketing and customer support services). Our agreements with our service providers include commitments that
    the service providers agree to limit their use of personal data and to comply with privacy and security standards at
    least as stringent as the terms of this Privacy Policy. Please bear in mind that if you provide personal data
    directly to a third party, such as through a link on our website, the processing is typically based on their
    policies and standards.
  </p>

  <h3>For other legitimate reasons.</h3>

  <p>
    If Aista is involved in a merger, acquisition or asset sale, we may transfer personal data to the third party
    involved. However, we will continue to ensure the confidentiality of all personal data. We will give notice to all
    Users concerned when the personal data are transferred or become subject to a different privacy policy as soon as
    reasonably possible.
  </p>

  <h3>With explicit consent.</h3>

  <p>
    We may share personal data with third parties outside Aista's organization for other reasons than the ones
    mentioned before, when we have the User's explicit consent to do so. The User has the right to withdraw this consent
    at all times.
  </p>

  <h3>Storage period.</h3>

  <p>
    Aista does not store personal data longer than is legally permitted and necessary for the purposes of providing
    the Services or the relevant parts thereof. The storage period depends on the nature of the information and the
    purposes of processing. The maximum period may therefore vary per use.
  </p>

  <p>
    Typically, we will store User's personal data for as long as the User or the relevant customer User represents is a
    registered subscriber or a registered user of our Services or for as long as we have another purpose to do so and,
    thereafter, for no longer than is required or permitted by law or reasonably necessary for internal reporting and
    reconciliation purposes.
  </p>

  <p>
    In general, personal data of Users are deleted within reasonable time after the User no longer uses any part of the
    Services or when the User makes a request regarding deletion of User's personal data.
  </p>

  <h3>Users’ rights.</h3>

  <p>
    Aista offers access for the Users to the personal data processed by Aista. This means that Users may contact us
    and we will inform what personal data we have collected and processed regarding the said User and the purposes such
    data are used for.
  </p>

  <h3>Right to withdraw consent.</h3>

  <p>
    In case the processing is based on a consent granted by User, User may withdraw the consent at any time. Withdrawing
    a consent may lead to fewer possibilities to use our Services.
  </p>

  <h3>Right to correct.</h3>

  <p>
    Users have the right to have incorrect, imprecise, incomplete, outdated, or unnecessary personal data we have stored
    about the User corrected or completed.
  </p>

  <h3>Right to deletion.</h3>

  <p>
    Users may also ask us to delete the User's personal data from our systems. We will comply with such request unless
    we have a legitimate ground to not delete the data. We may not immediately be able to delete all residual copies
    from our servers and backup systems after the active data have been deleted. Such copies shall be deleted as soon as
    reasonably possible.
  </p>

  <h3>Right to object.</h3>

  <p>
    Users may object to certain use of personal data if such data are processed for other purposes than purposes
    necessary for the performance of our Services to the User or for compliance with a legal obligation.
  </p>

  <p>
    Users may also object any further processing of personal data after prior given consent. If User objects the further
    processing of personal data, this may lead to fewer possibilities to use our Services.
  </p>

  <p>
    In the event and at the sole discretion of Aista, if it is determined that the Service can no longer be provided
    without customer acceptance of modified terms, a pro-rated refund of service will be paid back to the User.
  </p>

  <h3>Right to restriction of processing.</h3>

  <p>
    Users may request us to restrict certain processing of personal data, this may however lead to fewer possibilities
    to use our Services.
  </p>

  <h3>Right to data portability.</h3>

  <p>
    Users have the right to receive their personal data from us in a structured and commonly used format and to
    independently transmit those data to a third party.
  </p>

  <h3>How to use the rights.</h3>

  <p>
    The above mentioned rights may be used by sending a letter or an e-mail to us on the addresses set out above,
    including the following information: the full name, company name (if applicable), address, e-mail address and a
    phone number. We may request the provision of additional information necessary to confirm the identity of the User.
    We may reject requests that are unreasonably repetitive, excessive or manifestly unfounded.
  </p>

  <h3>Direct marketing.</h3>

  <p>
    Notwithstanding any consent granted beforehand for the purposes of direct marketing, User has the right to prohibit
    us from using User's personal data for direct marketing purposes, market research and profiling by contacting us on
    the addresses indicated above or by using the functionalities of the Services or the unsubscribe possibility offered
    in connection with any direct marketing messages.
  </p>

  <p>
    You have the right to opt out of receiving electronic direct marketing communications from us, and choosing not to
    receive marketing communications from us in the future. Aista uses the Users' e-mail addresses on record to
    communicate of updates and important information regarding the Aista infrastructure in the form of occasional
    newsletters. Users have the possibility to opt out of these e-mails by unsubscribing through the e-mail itself.
  </p>

  <p>
    You also have the right to prohibit us from using your personal data for direct marketing purposes, market research
    and profiling by contacting us on the addresses indicated above. In case your personal data are processed based on
    your consent, you have the right to withdraw your consent for such processing.
  </p>

  <h3>Lodging a complaint.</h3>

  <p>
    In case User considers our processing of personal data to be inconsistent with the applicable data protection laws,
    a complaint may be lodged with the local supervisory authority for data protection.
  </p>

  <h3>Information security.</h3>

  <p>
    We will take all reasonable and appropriate security measures to protect the personal data we store and process from
    unauthorised access or unauthorised alteration, disclosure or destruction. Measures include for example, where
    appropriate, encryption, firewalls, secure facilities and access right systems.
  </p>

  <p>
    We maintain all personal data collected through industry standard safe mechanisms including, but necessarily limited
    to encryption of password data as well as API keys, SSL-encryption, passing of credit card information directly to
    our PCI compliant Merchant Processor and encryption of any collected credit card information.
  </p>

  <p>
    We use administrative, organizational, technical, and physical safeguards to protect the personal data we collect
    and process. Our security controls are designed to maintain an appropriate level of data confidentiality, integrity,
    and availability. We regularly test our websites, data centres, systems, and other assets for security
    vulnerabilities.
  </p>

  <p>
    Should despite of the security measures, a security breach occur that is likely to have negative effects to the
    privacy of Users, we will inform the relevant Users and other affected parties, as well as relevant authorities when
    required by applicable data protection laws, about the breach as soon as reasonably possible.
  </p>

</mat-dialog-content>

<mat-dialog-actions align="end">

  <button
    mat-flat-button
    [mat-dialog-close]="true">
    Close
  </button>

</mat-dialog-actions>
